@media (max-width: 768px) {
    .mobile-row-th{
        display: flex !important;
        justify-content: center !important;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .mobile-row-th > a{
        margin-bottom: 2rem !important;
    }
    .mobile-row-th > a > img{
        width:125px;
        height: 125px;
    }

    .mobile-row-width-th{
        text-align: center;
    }
    .mobile-row-width-th > a{
        width: 50% !important;
        margin-bottom: 2rem !important;
        margin-top: 2rem !important;
    }

    .mobile-display-unset{
        display: unset !important;
    }

    .mobile-grid > div:first-child{
        display: block;
        margin-top: 30px;
    }
    .mobile-grid > div:nth-child(2){
        display: flex;
        flex-flow: unset;
    }

    .mobile-thead tr{
        display: flex;
        flex-wrap: wrap;
    }
    .mobile-thead th{
        box-sizing: border-box;
    }
    .mobile-thead th:nth-child(1),
    .mobile-thead th:nth-child(2){
        order: 1;
        flex: 1 1 50%;
    }
    .mobile-thead th:nth-child(3),
    .mobile-thead th:nth-child(4){
        order: 2;
        flex: 1 1 50%;
    }
    .mobile-thead th:last-child{
        order: 3;
        flex: 1 1 100%;
    }

    .mobile-table-th{
        border-bottom: 0;
        border-right: 1px solid #eae8e4;
        align-content: center;
    }
    .mobile-table-th > div:nth-child(1){
        border-bottom: 0 !important;
        float: left;
        display: unset !important;
        flex-wrap: unset !important;
        margin-right: unset !important;
        margin-left: unset !important;
        padding-top: 0.5rem !important;
        font-weight: bold;
    }
    .mobile-table-th > div:nth-child(2){
        display: inline-block;
        flex-wrap: unset !important;
        margin-right: unset !important;
        margin-left: unset !important;
        padding-bottom: 0.5rem !important;

    }
    .mobile-display-none{
        display: none !important;
    }

    .mobile-table-td{
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
    .mobile-table-td td:first-child{
        display: none;
    }
    .mobile-table-td td:nth-child(2){
        grid-column: span 2;
    }
    .mobile-table-td td{
        padding: 1rem !important;
        width: unset;
    }
}