@media (max-width: 768px) {
    .mobile-th{
        padding-right: 0 !important;
    }

    .first-col {
        order: 1;
        width: 20%;
    }
    .center-cols {
        order: 2;
        width: 50%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        display: unset;
    }
    .last-col {
        order: 3;
        text-align: right;
        width: 20%;
    }
    .center-cols:first-child {
        max-width: 100%;
        padding-left: 0 !important;
    }

    .table-row-mobile{
        margin-right: -15px;
        margin-left: -15px;
    }
    .text-left-mobile{
        text-align: left !important;
    }

    .container-track{
        display:block !important;
    }

}

.container-track{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}
.track-item1{
    grid-row: 1 / 3;
}