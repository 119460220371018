/* 앨범 상세 우측 사이드메뉴 */
.side-menu{
    position: sticky;
    top: 220px;
    height: 450px;
    width: 290px;
    background-color: #FFFFFF;
    padding: 15px;
    float: right;
    margin-right: 5%;
    border: 2px solid #eae8e4;
    z-index: 11;
}
/* 앨범 상세 말풍선 */
.tooltip {
    position: fixed;  /* 절대 위치 설정 */
    background-color: rgba(51, 51, 51, 0.9);
    color: #fff;
    padding: 8px;
    font-size: 14px;
    display: none;
    pointer-events: none;  /* 마우스 이벤트 방지 */
    z-index: 1000;  /* 다른 요소 위에 표시 */
    transition: opacity 0.2s;
    border-radius: 5px;
}
/* 앨범 상세 상단 탭 */
.content-header {
    position: sticky !important;
    background-color: #FFFFFF;
    z-index: 10;
    /*border-bottom: 1px solid #eae8e4;*/
}

.pad-total{
    display: unset;
}
.pad-inline-block{
    display: unset;
}

/* 태블릿 */
@media (max-width: 1200px) {
    .side-menu{
        position: unset;
        top: unset;
        height: unset;
        width: unset;
        background-color: unset;
        float: unset;
        margin-right: unset;
        border: unset;
        padding: 20px;
        text-align: center;
    }
    .pad-total{
        display: ruby;
    }
    .pad-inline-block{
        display: inline-block;
    }
}

/* 모바일 */
@media (max-width: 768px) {
    .content-header{
        position: sticky !important;
        background-color: #FFFFFF;
        z-index: 10;
        width: 100%;
    }
    .side-menu{
        position: unset;
        top: unset;
        height: unset;
        width: unset;
        background-color: unset;
        float: unset;
        margin-right: unset;
        border: unset;
        padding: 20px;
        text-align: unset;
    }
    .pad-total{
        display: unset;
    }
    .pad-inline-block{
        display: unset;
    }

}

.center-cols:first-child {
    flex: 0 0 75%;
    max-width: 75%;
}