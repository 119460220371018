.banner-w-25{
    width: 25% !important;
}
.banner-w-75{
    width: 75% !important;
}
.banner-pl-4{
    padding-left: 1.5rem !important;
}
.banner-d-flex{
    display: flex !important;
}

@media (max-width: 768px) {
    .banner-d-flex {
        flex-direction: column; /* 배너를 위아래로 배치 */
    }
    .banner-w-25{
        width: 100% !important;
    }
    .banner-w-75{
        width: 100% !important;
    }
    .banner-pl-4{
        padding-left: 0rem !important;

    }
}
